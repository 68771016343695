@import url('https://fonts.googleapis.com/css2?family=LXGW+WenKai+TC&display=swap');


* {
  font-family: "LXGW WenKai TC", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, "Noto Sans", Arial, "Odoo Unicode Support Noto", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.root {
  color-scheme: light only !important;
}

body {
  min-height: 100%;
  width: 100%;
  margin: 0;
  font-family: "LXGW WenKai TC", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, "Noto Sans", Arial, "Odoo Unicode Support Noto", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}


html {
  display: flex;
  min-height: 100%;
  min-width: 200px;
  overflow: auto;
  scrollbar-width: thin;
  background-color: white;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.MuiDrawer-paper {
  scrollbar-width: "thin";
}

a:active,
a:focus {
  -webkit-tap-highlight-color: transparent;
  background-color: inherit;
}

.top-menu {
  min-width: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.MuiPaper-root.MuiPaper-outlined {
  border: 1px solid #b9b9b9;
}


/* for desktop version, muicontainer-root's min width = 350 */
@media (max-width: 1024px) {
  html {
    min-width: 350px;
  }
}